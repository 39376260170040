import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";

const KpiCards: React.FC<any> = ({ kpiValues }) => {
  return (
    <div className="row mb-5">
      <div className="col-md-4">
        <div className="greyCard kpCard">
          <div className="row align-items-center">
            <div className="col-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="39"
                viewBox="0 0 41 39"
                fill="none"
              >
                <path
                  d="M30.4655 11.7287C30.0959 11.6969 29.8272 11.3861 29.8608 11.0372L30.0825 8.71524L18.2029 17.5144C17.9274 17.7174 17.531 17.6857 17.2958 17.4446L14.1647 14.2916L5.78581 20.4898C5.66486 20.5786 5.51704 20.623 5.37594 20.623C5.17436 20.623 4.9795 20.5405 4.84512 20.3819C4.61666 20.1028 4.67042 19.7094 4.95934 19.4938L13.822 12.934C14.0908 12.731 14.4872 12.7627 14.7224 12.9975L17.8535 16.1568L29.1552 7.78901L26.8237 7.586C26.4541 7.55428 26.1786 7.24977 26.2122 6.90085C26.2458 6.55193 26.5683 6.29182 26.9446 6.32354L30.956 6.67246C31.1374 6.68515 31.3121 6.76128 31.4331 6.91354C31.5607 7.05945 31.5943 7.23708 31.5607 7.40837L31.1979 11.1577C31.1643 11.4812 30.8754 11.7287 30.526 11.7287H30.4655Z"
                  fill="#F1D513"
                />
                <path
                  d="M27.5491 21.2571V32.0419C27.5491 32.3908 27.2467 32.6763 26.8772 32.6763H22.8456C22.4694 32.6763 22.1737 32.3908 22.1737 32.0419V24.4291C22.1737 24.2451 22.2611 24.0611 22.4156 23.9406L26.4471 20.7686C26.642 20.6163 26.9242 20.5783 27.1594 20.6861C27.3945 20.7876 27.5491 21.0096 27.5491 21.2571Z"
                  fill="#F1D513"
                />
                <path
                  d="M19.4867 21.8921V32.0425C19.4867 32.3915 19.1843 32.6769 18.8148 32.6769H14.7832C14.407 32.6769 14.1113 32.3915 14.1113 32.0425V24.4297C14.1113 24.2203 14.2188 24.0237 14.407 23.9031L18.4385 21.3655C18.6468 21.2387 18.9088 21.226 19.1306 21.3338C19.3456 21.448 19.4867 21.6574 19.4867 21.8921Z"
                  fill="#F1D513"
                />
                <path
                  d="M35.6115 11.741V32.0419C35.6115 32.3908 35.3091 32.6763 34.9395 32.6763H30.908C30.5317 32.6763 30.2361 32.3908 30.2361 32.0419V14.913C30.2361 14.729 30.3234 14.545 30.478 14.4245L34.5095 11.2525C34.7044 11.1002 34.9866 11.0622 35.2217 11.17C35.4569 11.2715 35.6115 11.4936 35.6115 11.741Z"
                  fill="#F1D513"
                />
                <path
                  d="M11.4222 27.6006V32.0414C11.4222 32.3903 11.1199 32.6758 10.7503 32.6758H6.7188C6.34252 32.6758 6.04688 32.3903 6.04688 32.0414V30.1382C6.04688 29.9288 6.15438 29.7322 6.34252 29.6116L10.374 27.074C10.5823 26.9471 10.8444 26.9345 11.0661 27.0423C11.2811 27.1565 11.4222 27.3659 11.4222 27.6006Z"
                  fill="#F1D513"
                />
              </svg>
              <p>Q3 Volume </p>
            </div>
            <div className="col-7 text-end">
              <h1>{kpiValues?.Volume?.ACLEGACQ224} <div className="yellow font_26">Mhl</div></h1>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-5">
              <span>
                <div className="yellow">{kpiValues?.Volume?.lyComparison}%</div>{" "}
                vs LY
              </span>
            </div>
            <div className="col-7 text-end">
              <span>
                <div className="yellow">{kpiValues?.Volume?.buComparison}%</div>{" "}
                vs Budget
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="greyCard kpCard">
          <div className="row align-items-center">
            <div className="col-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <g clip-path="url(#clip0_411_3015)">
                  <path
                    d="M10.6569 6.49998H21.3449L23.6516 4.65465C24.4369 4.02798 24.7303 3.01065 24.3983 2.06132C24.0663 1.11198 23.2036 0.499985 22.2009 0.499985H9.80092C8.79825 0.499985 7.93558 1.11332 7.60358 2.06132C7.27158 3.00932 7.56492 4.02798 8.34892 4.65332L10.6569 6.49998Z"
                    fill="#F1D513"
                  />
                  <path
                    d="M21.5267 8.49998H10.4733C6.832 12.0747 4 18.3467 4 23.5C4 27.9787 6.37067 32.5 11.6667 32.5H20.6667C25.1907 32.5 28 29.0507 28 23.5C28 18.3467 25.168 12.0747 21.5267 8.49998ZM15.4933 19.5H16.5067C18.0653 19.5 19.3333 20.768 19.3333 22.3267C19.3333 23.728 18.3227 24.8813 17 25.116V26.1653C17 26.7173 16.552 27.1653 16 27.1653C15.448 27.1653 15 26.7173 15 26.1653V25.1667H13.6667C13.1147 25.1667 12.6667 24.7187 12.6667 24.1667C12.6667 23.6147 13.1147 23.1667 13.6667 23.1667H16.5067C16.9627 23.1667 17.3333 22.796 17.3333 22.34C17.3333 21.8707 16.9627 21.5 16.5067 21.5H15.4933C13.9347 21.5 12.6667 20.232 12.6667 18.6733C12.6667 17.272 13.6773 16.1187 15 15.884V14.8333C15 14.2813 15.448 13.8333 16 13.8333C16.552 13.8333 17 14.2813 17 14.8333V15.8333H18.3333C18.8853 15.8333 19.3333 16.2813 19.3333 16.8333C19.3333 17.3853 18.8853 17.8333 18.3333 17.8333H15.4933C15.0373 17.8333 14.6667 18.204 14.6667 18.66C14.6667 19.1293 15.0373 19.5 15.4933 19.5Z"
                    fill="#F1D513"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_411_3015">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(0 0.499985)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>Q3 Net Revenue </p>
            </div>
            <div className="col-7 text-end">
              <h1>{"$"}{kpiValues && kpiValues["Net Revenue"]?.ACLEGACQ224} <div className="yellow font_26">bn</div></h1>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-5">
              <span>
                <div className="yellow">
                  {kpiValues && kpiValues["Net Revenue"]?.lyComparison}%
                </div>{" "}
                vs LY
              </span>
            </div>
            <div className="col-7 text-end">
              <span>
                <div className="yellow">
                  {kpiValues && kpiValues["Net Revenue"]?.buComparison}%
                </div>{" "}
                vs Budget
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="greyCard kpCard">
          <div className="row align-items-center">
            <div className="col-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <path
                  d="M7.5 22.5C7.77614 22.5 8 22.2761 8 22C8 21.7238 7.77614 21.5 7.5 21.5C7.22386 21.5 7 21.7238 7 22C7 22.2761 7.22386 22.5 7.5 22.5Z"
                  fill="#F1D513"
                />
                <path
                  d="M3.50024 25.5001H9.50024C9.89807 25.5001 10.2796 25.3421 10.5609 25.0608C10.8422 24.7794 11.0002 24.3979 11.0002 24.0001V20.0001C11.0002 19.6023 10.8422 19.2207 10.5609 18.9394C10.2796 18.6581 9.89807 18.5001 9.50024 18.5001H3.50024C3.10242 18.5001 2.72089 18.6581 2.43958 18.9394C2.15828 19.2207 2.00024 19.6023 2.00024 20.0001V24.0001C2.00024 24.3979 2.15828 24.7794 2.43958 25.0608C2.72089 25.3421 3.10242 25.5001 3.50024 25.5001ZM7.50024 20.5001C7.79692 20.5001 8.08693 20.5881 8.3336 20.7529C8.58027 20.9177 8.77253 21.152 8.88606 21.4261C8.9996 21.7002 9.0293 22.0018 8.97142 22.2927C8.91354 22.5837 8.77068 22.851 8.5609 23.0608C8.35113 23.2705 8.08385 23.4134 7.79288 23.4713C7.50191 23.5291 7.20031 23.4994 6.92622 23.3859C6.65213 23.2724 6.41786 23.0801 6.25304 22.8334C6.08822 22.5868 6.00024 22.2968 6.00024 22.0001C6.00024 21.6023 6.15828 21.2207 6.43958 20.9394C6.72089 20.6581 7.10242 20.5001 7.50024 20.5001Z"
                  fill="#F1D513"
                />
                <path
                  d="M27.5 13.5H6.5C5.8372 13.5008 5.20178 13.7644 4.73311 14.2331C4.26444 14.7018 4.00079 15.3372 4 16V17.5H9.5C10.1628 17.5008 10.7982 17.7644 11.2669 18.2331C11.7356 18.7018 11.9992 19.3372 12 20V24C11.9992 24.6628 11.7356 25.2982 11.2669 25.7669C10.7982 26.2355 10.1628 26.4992 9.5 26.5H4V28C4.00079 28.6628 4.26444 29.2982 4.73311 29.7669C5.20178 30.2355 5.8372 30.4992 6.5 30.5H27.5C28.1628 30.4992 28.7982 30.2355 29.2669 29.7669C29.7356 29.2982 29.9992 28.6628 30 28V16C29.9992 15.3372 29.7356 14.7018 29.2669 14.2331C28.7982 13.7644 28.1628 13.5008 27.5 13.5ZM24.5 28.5H21.5C21.3674 28.5 21.2402 28.4473 21.1464 28.3535C21.0527 28.2598 21 28.1326 21 28C21 27.8674 21.0527 27.7402 21.1464 27.6464C21.2402 27.5527 21.3674 27.5 21.5 27.5H24.5C24.6326 27.5 24.7598 27.5527 24.8536 27.6464C24.9473 27.7402 25 27.8674 25 28C25 28.1326 24.9473 28.2598 24.8536 28.3535C24.7598 28.4473 24.6326 28.5 24.5 28.5ZM27.5 28.5H26.5C26.3674 28.5 26.2402 28.4473 26.1464 28.3535C26.0527 28.2598 26 28.1326 26 28C26 27.8674 26.0527 27.7402 26.1464 27.6464C26.2402 27.5527 26.3674 27.5 26.5 27.5H27.5C27.6326 27.5 27.7598 27.5527 27.8536 27.6464C27.9473 27.7402 28 27.8674 28 28C28 28.1326 27.9473 28.2598 27.8536 28.3535C27.7598 28.4473 27.6326 28.5 27.5 28.5Z"
                  fill="#F1D513"
                />
                <path
                  d="M7.55664 12.4996H12.2731C13.4792 11.1567 14.5856 9.72757 15.5836 8.22363C16.3435 7.07012 17.0377 5.87472 17.6631 4.64313L12.5276 3.51263C12.3963 3.48215 12.2588 3.4919 12.1331 3.54063C12.0888 3.55955 12.0479 3.58574 12.0121 3.61813C11.965 3.66215 11.9286 3.71637 11.9056 3.77663C11.7151 4.53487 11.4853 5.28271 11.2171 6.01713C11.1126 6.30313 10.7801 7.18963 10.2786 8.21713C9.53152 9.74036 8.61866 11.1765 7.55664 12.4996Z"
                  fill="#F1D513"
                />
                <path
                  d="M26.6339 12.4997C28.4674 9.34967 29.7769 7.03518 29.9339 6.66418C30.0009 6.5286 30.0143 6.3727 29.9714 6.22768C29.9447 6.1555 29.9027 6.08998 29.8482 6.03567C29.7938 5.98136 29.7281 5.93956 29.6559 5.91318L20.2859 2.52918C20.1786 2.49208 20.0623 2.48975 19.9536 2.52252C19.8449 2.55528 19.7493 2.62147 19.6804 2.71168C18.7782 4.82749 17.6861 6.85714 16.4174 8.77568C15.5554 10.0735 14.6144 11.3172 13.5999 12.4997H26.6339Z"
                  fill="#F1D513"
                />
              </svg>
              <p>Q3 MACO </p>
            </div>
            <div className="col-7 text-end">
              <h1>{"$"}{kpiValues?.MACO?.ACLEGACQ224} <div className="yellow font_26">bn</div></h1>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-5">
              <span>
                <div className="yellow">{kpiValues?.MACO?.lyComparison}%</div>{" "}
                vs LY
              </span>
            </div>
            <div className="col-7 text-end">
              <span>
                <div className="yellow">{kpiValues?.MACO?.buComparison}%</div>{" "}
                vs Budget
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiCards;

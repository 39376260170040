import React from "react";
import "./loader.css";
import logo from "../../assets/images/siteLogo.svg";
import User1 from "../../assets/images/user1.png";

export default function Loader() {
  setTimeout(function() {
     document.getElementsByClassName("loaderContainer")[0].classList.add("hide-after-2s");
  }, 1500);

  return (
    <div className="loaderContainer">
      <div className="item">
			  <i className="loader --7"></i>
      </div>
    </div>

  );
}

import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import VBarChart from "../../../graphs/VBarChart";

interface ExcelData {
  Country: string;
  Period: string | number; // Period can now be a string or a number (Excel serial date)
  CPI: number; // Renamed "Consumer Price Index" to "CPI"
}

interface Props {
  updateEvent: (data: any) => void;
  updateEventData: (data: any) => void;
}

// Helper function to convert Excel serial date to JavaScript Date object
const excelDateToJSDate = (serial: number) => {
  const date = new Date((serial - 25569) * 86400 * 1000); // Convert Excel serial date to JS date
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
  return formattedDate; // Return date without time
};

const PercentageChangeCPI: React.FC<Props> = ({
  updateEvent,
  updateEventData,
}) => {
  const [percentageData, setPercentageData] = useState<
    { country: string; value: number }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/Q3 Section 3 data.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Read the workbook from the array buffer
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[1]];

        // Convert the worksheet to JSON
        const jsonData = utils.sheet_to_json<any>(worksheet);

        // Filter out rows without valid data and rename "Consumer Price Index" to "CPI"
        const filteredData = jsonData
          .filter(
            (row: any) =>
              row.Country &&
              row.Period &&
              row["Consumer Price Index_monthly"] !== undefined
          )
          .map((row) => ({
            ...row,
            Period:
              typeof row.Period === "number" // Check if the Period is an Excel serial date
                ? excelDateToJSDate(row.Period) // Convert to a string in 'MM/DD/YYYY' format
                : row.Period, // Use the string as-is if it's not a number
            CPI: row["Consumer Price Index_monthly"], // Renaming the column to CPI
          }));

        // Group data by Country
        const groupedData: { [key: string]: any[] } = {};
        filteredData.forEach((row: any) => {
          if (row.Country === "Argentina") return;
          if (!groupedData[row.Country]) {
            groupedData[row.Country] = [];
          }
          groupedData[row.Country].push(row);
        });

        // Calculate percentage change for CPI between 2023 and 2024
        const percentageData: { country: string; value: number }[] = [];

        Object.keys(groupedData).forEach((country) => {
          const countryData = groupedData[country];

          // Parse the date and filter data for 2023 and 2024
          const data2023 = countryData
            .filter((item) => {
              const date = new Date(item.Period);
              return date.getFullYear() === 2023;
            })
            .slice(0, 12); // Limit to the first 9 months of 2023

          const data2024 = countryData
            .filter((item) => {
              const date = new Date(item.Period);
              return date.getFullYear() === 2024;
            })
            .slice(0, 12); // Limit to the first 9 months of 2024

          // Calculate average CPI for 2023
          const avgCPI2023 =
            data2023.reduce((sum, item) => sum + item.CPI, 0) / data2023.length;

          // Calculate average CPI for 2024
          const avgCPI2024 =
            data2024.reduce((sum, item) => sum + item.CPI, 0) / data2024.length;

          // Calculate percentage change
          if (avgCPI2023 !== 0) {
            const percentageChange = (avgCPI2024 / avgCPI2023 - 1) * 100;
            percentageData.push({
              country: country,
              value: isNaN(percentageChange)
                ? 0
                : Number(percentageChange.toFixed(1)),
            });
          } else {
            percentageData.push({
              country: country,
              value: 0,
            });
          }
        });
        updateEvent(percentageData);
        updateEventData({ CPI: percentageData });
        setPercentageData(percentageData);
        console.log("Percentage Change CPI Data:", percentageData);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {percentageData.length && (
        <VBarChart
          data={percentageData}
          id="percentageChangeCPI"
          is_xAxis={false}
        />
      )}
    </div>
  );
};

export default PercentageChangeCPI;

import BarChart from "../../graphs/BarChart";
import React, { useEffect, useState, useRef } from "react";
import { read, utils } from "xlsx";
import WaterfallChart from "../../graphs/WaterfallChart";
import { diffieHellman } from "crypto";

interface President {
  Country: string;
  LEVersion: string;
  BU: number;
  Account: string;
  LY: number;
  GAC: string;
  ACLEGACQ224: string;
  LYAmendedQ124: number;
  BUAmendedQ224: string;
}

interface Condition {
  Account: string;
  Country: string;
  LEVersion: string;
}

interface Result {
  Account: string;
  Country: string;
  LEVersion: string;
  actualValue: number;
  forecastValue: number;
  difference: string;
}

interface BoxResult {
  Country: string;
  BoxValue: string;
}

interface WaterfallChartProps {
  data: {
    columnConfig?: { fill: any };
    Country: string;
    value: number;
    open: number;
    stepValue?: number;
    displayValue: number;
  }[];
}

interface President_box {
  Country: string;
  LEVersion: string;
  BU: number;
  Account: string;
  LY: number;
  GAC: string; // Treating GAC as a string to handle mixed content
  ACLEGACQ224: string;
  LYAmendedQ124: number;
  BUAmendedQ224: number;
}

interface Condition_box {
  Account: string;
  Country: string;
  LEVersion: string;
}

interface Result_box {
  Account: string;
  Country: string;
  LEVersion: string;
  actualValue: number;
  forecastValue: number;
  difference: string;
}

const WinsCharts = () => {
  const stepValuesRef = useRef<HTMLDivElement>(null);

  const [pres, setPres] = useState<President[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [pres_box, setPres_box] = useState<President[]>([]);
  const [results_box, setResults_box] = useState<Result[]>([]);
  const [Boxresults, setBoxResults] = useState<BoxResult[]>([]);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [BoxformattedData, setBoxFormattedData] = useState<any[]>([]);

  const [finalResults, setFinalResult] = useState<WaterfallChartProps>();
  const [totalABI, setTotalABI] = useState<{
    actualValue: number;
    forecastValue: number;
  } | null>(null);
  //   const [sixCountriesActualConditions, setSixCountriesActualConditions] = useState<Condition[]>([]);
  //   const [sixCountriesForecastConditions, setSixCountriesForecastConditions] = useState<Condition[]>([]);
  const [sixCountriesActualValue, setSixCountriesActualValue] =
    useState<number>(0);
  const [sixCountriesForecastValue, setSixCountriesForecastValue] =
    useState<number>(0);
  const [sixCountriesDifference, setSixCountriesDifference] =
    useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/Q3PulseReportData.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Ensure the file is read as binary
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Generate array of presidents from the first worksheet
        const data: any[] = utils.sheet_to_json<any>(worksheet);
        const renamedData = data.map((row) => ({
          ...row,
          Account: row["Accounts IM Simplified"], // Rename column
          Country: row["GAC Reporting Country"],  // Rename column
          LEVersion: row["SS: CY Versions"],      // Rename column
          ACLEGACQ324: row["AC/LEQ3"]

          // Add any other necessary renames here
        }));

        setPres(renamedData);
      
        setPres(data);

        let CountryForecastValue = 0;
        let CountryActualValue = 0;

        // data.forEach((item) => {
        //   if (item.Country !== "Total ABI" && item.Account === "Volume") {
        //     if (item.LEVersion === "LE 9+3 2024") {
        //       CountryActualValue += Number(item.ACLEGACQ224/1000);
        //     }

        //     if (item.LEVersion === "LE 6+6 2024") {
        //       CountryForecastValue += Number(item.ACLEGACQ224/1000);
        //     }
        //   }
        // });

       
        // Define conditions for ActualValue and ForecastValue
        const actualConditions: any[] = [
          { Account: "Volume", Country: "US Beer", LEVersion: "LE 9+3 2024" },
          { Account: "Volume", Country: "Mexico", LEVersion: "LE 9+3 2024" },
          {
            Account: "Volume",
            Country: "Brazil Beer",
            LEVersion: "LE 9+3 2024",
          },
          { Account: "Volume", Country: "China", LEVersion: "LE 9+3 2024" },
          {
            Account: "Volume",
            Country: "Colombia Beer",
            LEVersion: "LE 9+3 2024",
          },
          { Account: "Volume", Country: "EUR", LEVersion: "LE 9+3 2024" },
          { Account: "Volume", Country: "Peru Beer", LEVersion: "LE 9+3 2024" },
          {
            Account: "Volume",
            Country: "South-Africa",
            LEVersion: "LE 9+3 2024",
          },
          {
            Account: "Volume",
            Country: "Dominican Republic Beer",
            LEVersion: "LE 9+3 2024",
          },
          {
            Account: "Volume",
            Country: "Argentina Beer",
            LEVersion: "LE 9+3 2024",
          },
          { Account: "Volume", Country: "Total ABI", LEVersion: "LE 9+3 2024" },
          // Add more conditions as needed
        ];

        const forecastConditions: any[] = [
          { Account: "Volume", Country: "US Beer", LEVersion: "LE 6+6 2024" },
          { Account: "Volume", Country: "Mexico", LEVersion: "LE 6+6 2024" },
          {
            Account: "Volume",
            Country: "Brazil Beer",
            LEVersion: "LE 6+6 2024",
          },
          { Account: "Volume", Country: "China", LEVersion: "LE 6+6 2024" },
          {
            Account: "Volume",
            Country: "Colombia Beer",
            LEVersion: "LE 6+6 2024",
          },
          { Account: "Volume", Country: "EUR", LEVersion: "LE 6+6 2024" },
          {
            Account: "Volume",
            Country: "Peru Beer",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "South-Africa",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "Dominican Republic Beer",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "Argentina Beer",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "Total ABI",
            LEVersion: "LE 6+6 2024",
          },
          // Add more conditions as needed
        ];

        // Define conditions for the six specified countries for both actual and forecast values
        const sixCountriesActualConditions: any[] = [
          { Account: "Volume", Country: "Canada", LEVersion: "LE 9+3 2024" },
          {
            Account: "Volume",
            Country: "Ecuador Beer",
            LEVersion: "LE 9+3 2024",
          },
          {
            Account: "Volume",
            Country: "South Korea",
            LEVersion: "LE 9+3 2024",
          },
          {
            Account: "Volume",
            Country: "Honduras Beer",
            LEVersion: "LE 9+3 2024",
          },
          {
            Account: "Volume",
            Country: "El Salvador Beer",
            LEVersion: "LE 9+3 2024",
          },
          { Account: "Volume", Country: "Nigeria", LEVersion: "LE 9+3 2024" },
        ];

        const sixCountriesForecastConditions: any[] = [
          { Account: "Volume", Country: "Canada", LEVersion: "LE 6+6 2024" },
          {
            Account: "Volume",
            Country: "Ecuador Beer",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "South Korea",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "Honduras Beer",
            LEVersion: "LE 6+6 2024",
          },
          {
            Account: "Volume",
            Country: "El Salvador Beer",
            LEVersion: "LE 6+6 2024",
          },
          { Account: "Volume", Country: "Nigeria", LEVersion: "LE 6+6 2024" },
        ];

        const tempResults: Result[] = [];
        const BoxtempResults: BoxResult[] = [];
        let totalABIActualValue = 0;
        let totalABIForecastValue = 0;
        let sixCountriesActualValueTemp = 0;
        let sixCountriesForecastValueTemp = 0;
        let RestCountryForecastValue = 0;
        let RestCountryActualValue = 0;

        let BoxtotalABIActualValue = 0;
        let BoxtotalABIForecastValue = 0;
        let BoxsixCountriesActualValueTemp = 0;
        let BoxsixCountriesForecastValueTemp = 0;
        let BoxRestCountryForecastValue = 0;
        let BoxRestCountryActualValue = 0;
        let BoxCountryForecastValue = 0;
        let BoxCountryActualValue = 0;

        actualConditions.forEach((actualCondition, index) => {
          const forecastCondition = forecastConditions[index];
          const actualRow = renamedData.find(
            (row) =>
              row.Account === actualCondition.Account &&
              row.Country === actualCondition.Country &&
              row.LEVersion === actualCondition.LEVersion
          );
          const forecastRow = renamedData.find(
            (row) =>
              row.Account === forecastCondition.Account &&
              row.Country === forecastCondition.Country &&
              row.LEVersion === forecastCondition.LEVersion
          );

          if (actualRow && forecastRow) {
            const actualValue = parseFloat(actualRow.ACLEGACQ324);
            const forecastValue = parseFloat(forecastRow.BUAMQ3);

            if (!isNaN(actualValue) && !isNaN(forecastValue)) {
              const difference = ((actualValue - forecastValue) / 1000).toFixed(
                1
              );
              BoxtempResults.push({
                Country: actualCondition.Country,
                BoxValue: `${difference}`, // Format difference
              });

              // Store the ActualValue and ForecastValue for "Total ABI"
              if (
                actualCondition.Country === "Total ABI" &&
                actualCondition.Account === "Volume" &&
                actualCondition.LEVersion === "LE 9+3 2024"
              ) {
                BoxtotalABIActualValue = Number((actualValue / 1000).toFixed(1));
              } else {
                BoxCountryActualValue += actualValue ;
              }


              if (
                forecastCondition.Country === "Total ABI" &&
                forecastCondition.Account === "Volume" &&
                forecastCondition.LEVersion === "LE 6+6 2024"
              ) {
                BoxtotalABIForecastValue = Number(
                  (forecastValue / 1000).toFixed(1)
                );
              } else {
                BoxCountryForecastValue += forecastValue ;
              }
            }
          }
        });
      
      
        const BUAmmendedObject = {
          Country: "FYBUAmmended",
          BoxValue: `${BoxtotalABIForecastValue}`, // Adjust BoxValue as needed
        };
        const BoxFYGACObject = {
          Country: "FYGAC",
          BoxValue: `${BoxtotalABIActualValue}`,
        };

        // Calculate actual and forecast values for six specified countries
        sixCountriesActualConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const countryActualValue = parseFloat(matchingRow.ACLEGACQ324);
            if (!isNaN(countryActualValue)) {
              BoxsixCountriesActualValueTemp += countryActualValue;
            }
          }
        });

        sixCountriesForecastConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const countryForecastValue = parseFloat(matchingRow.BUAMQ3);
            if (!isNaN(countryForecastValue)) {
              BoxsixCountriesForecastValueTemp += countryForecastValue;
            }
          }
        });

        const BoxsixCountriesDifference = (
          (BoxsixCountriesActualValueTemp - BoxsixCountriesForecastValueTemp) /
          1000
        ).toFixed(1);
        BoxtempResults.push({
          Country: "Next5",
          BoxValue: `${BoxsixCountriesDifference}`, // Format difference
        });

        BoxRestCountryActualValue = (BoxsixCountriesActualValueTemp + BoxCountryActualValue)/1000 //10+6 countries actual value sun
        BoxRestCountryForecastValue = (BoxsixCountriesForecastValueTemp + BoxCountryForecastValue)/1000


        if (BoxtotalABIForecastValue - BoxRestCountryForecastValue !== 0) {
          const BoxcalculatedPercent =
            (BoxtotalABIActualValue - BoxRestCountryActualValue) - 
            (BoxtotalABIForecastValue - BoxRestCountryForecastValue);
          BoxtempResults.push({

            Country: "Tail Market",
            BoxValue: `${(BoxcalculatedPercent).toFixed(1)}`, 
          });
        }

        setBoxResults(BoxtempResults);
        const BoxFormattedResults = [
          BUAmmendedObject,
          ...BoxtempResults,
          BoxFYGACObject,
        ];

        const BoxfilteredArray = BoxFormattedResults.filter(
          (item) => item.Country !== "Total ABI"
        );

        setBoxFormattedData(BoxfilteredArray);


        //calculations for WATERFALL chart
        actualConditions.forEach((actualCondition, index) => {
          const forecastCondition = forecastConditions[index];
          const actualRow = renamedData.find(
            (row) =>
              row.Account === actualCondition.Account &&
              row.Country === actualCondition.Country &&
              row.LEVersion === actualCondition.LEVersion
          );
          const forecastRow = renamedData.find(
            (row) =>
              row.Account === forecastCondition.Account &&
              row.Country === forecastCondition.Country &&
              row.LEVersion === forecastCondition.LEVersion
          );

          if (actualRow && forecastRow) {
            const actualValue = parseFloat(actualRow.ACLEGACQ324);
            const forecastValue = parseFloat(forecastRow.ACLEGACQ324);

            if (!isNaN(actualValue) && !isNaN(forecastValue)) {
              const difference = ((actualValue - forecastValue) / 1000).toFixed(
                1
              ); // Calculate difference
              tempResults.push({
                Account: actualCondition.Account,
                Country: actualCondition.Country,
                LEVersion: actualCondition.LEVersion,
                actualValue,
                forecastValue,
                difference: `${difference}`, // Format difference
              });

              // Store the ActualValue and ForecastValue for "Total ABI"
              if (
                actualCondition.Country === "Total ABI" &&
                actualCondition.Account === "Volume" &&
                actualCondition.LEVersion === "LE 9+3 2024"
              ) {
                totalABIActualValue = Number((actualValue / 1000).toFixed(1));
              } else {
                CountryActualValue += actualValue ;
              }
              

              if (
                forecastCondition.Country === "Total ABI" &&
                forecastCondition.Account === "Volume" &&
                forecastCondition.LEVersion === "LE 6+6 2024"
              ) {
                totalABIForecastValue = Number(
                  (forecastValue / 1000).toFixed(1)
                );
              } else {
                CountryForecastValue += forecastValue ;
              }
            }
          }
        });
        


        const ForecastObject = {
          Country: "Global LE(6+6)",
          value: totalABIForecastValue,
          open: 0, // Adjust open value as needed
          stepValue: totalABIForecastValue,
          columnConfig: {}, // Add empty columnConfig as placeholder
          displayValue: totalABIForecastValue,
        };
        sixCountriesActualConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const countryActualValue = parseFloat(matchingRow.ACLEGACQ324);
            if (!isNaN(countryActualValue)) {
              sixCountriesActualValueTemp += countryActualValue;
            }
          }
        });

        sixCountriesForecastConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const countryForecastValue = parseFloat(matchingRow.ACLEGACQ324);
            if (!isNaN(countryForecastValue)) {
              sixCountriesForecastValueTemp += countryForecastValue;
            }
          }
        });
        

        const sixCountriesDifference = (
          (sixCountriesActualValueTemp - sixCountriesForecastValueTemp) /
          1000
        ).toFixed(1);
        tempResults.push({
          Account: "Volume",
          Country: "Next5",
          LEVersion: "LE 9+3 2024",
          actualValue: sixCountriesActualValueTemp,
          forecastValue: sixCountriesForecastValueTemp,
          difference: `${sixCountriesDifference}`, // Format difference
        });

        RestCountryActualValue =(CountryActualValue + sixCountriesActualValueTemp)/1000;
        RestCountryForecastValue =(CountryForecastValue + sixCountriesForecastValueTemp)/1000;
        
       

        if (totalABIForecastValue - RestCountryForecastValue !== 0) {
          const calculatedPercent =
            (totalABIActualValue -RestCountryActualValue) -
            (totalABIForecastValue - RestCountryForecastValue);
          tempResults.push({
            Account: "Volume",
            Country: "Tail Market",
            LEVersion: "LE 9+3 2024",
            actualValue: totalABIActualValue - RestCountryActualValue,
            forecastValue: totalABIForecastValue - RestCountryForecastValue,
            difference: `${(calculatedPercent).toFixed(1)}`, 
          });
        }
        const filteredArray = tempResults.filter(
          (item) => item.Country !== "Total ABI"
        );

        var openValue = totalABIForecastValue;
        const formattedResults = filteredArray.map((result, index) => {
          const oldValue = openValue;
          openValue = Number(
            (openValue + parseFloat(result.difference)).toFixed(1)
          );
          return {
            Country: result.Country.replace("-", " "),
            value: openValue,
            open: oldValue,
            stepValue: openValue,
            columnConfig: {
              //fill: am5.color(stepValue < 0 ? 0x0000ff : 0xffff00), // Blue for negative, Yellow for positive
            },
            displayValue: result.difference,
            //BoxValue: (result.actualValue - result.forecastValue) / 1000,
          };
        });
        const ActualsObject = {
          Country: "Actuals",
          value: totalABIActualValue,
          open: 0, // Use stepValue of the previous item as open value
          stepValue: totalABIActualValue,
          columnConfig: {}, // Placeholder for column configuration
          displayValue: totalABIActualValue,
          BoxValue: 0, // Adjust BoxValue as needed
        };

        setFormattedData(formattedResults);
        const addedFormattedResults = [
          ForecastObject, // Insert LYAmmendedObject at index 0 (first index)
          ...formattedResults, // Spread remaining elements from formattedResults
          ActualsObject, // Insert FYGACObject at the end (last index)
        ];

        setFormattedData(addedFormattedResults);

        

        setResults(tempResults);
        setTotalABI({
          actualValue: totalABIActualValue,
          forecastValue: totalABIForecastValue,
        });
        setSixCountriesActualValue(sixCountriesActualValueTemp);
        setSixCountriesForecastValue(sixCountriesForecastValueTemp);
        setSixCountriesDifference(sixCountriesDifference);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (results.length) {
      let currentValue = results[0].actualValue;
      var previousValue = 0;
      const transformedData: any[] = results.map((item, index) => {
        // if (index === 0){
        //   return {
        //     Country: index ? item.Country : "Global LE (0+12)",
        //     value: item.forecastValue - item.actualValue,
        //     open:  Number(openValue.toFixed(1)),
        //     stepValue:   Number(stepValue.toFixed(1)),
        //     displayValue:  Number(displayValue.toFixed(1)),
        //   };
        // }
        previousValue =
          index === 0 ? 0 : previousValue + results[index - 1].actualValue;
        const openValue = index === 0 ? 0 : currentValue;
        const stepValue =
          index === 0 ? item.actualValue : currentValue - item.actualValue;
        const displayValue = item.actualValue;

        currentValue = stepValue;

        return {
          Country: index ? item.Country : "Global LE (0+12)",
          value: stepValue,
          open: Number(openValue.toFixed(1)),
          stepValue: Number(stepValue.toFixed(1)),
          displayValue: Number(displayValue.toFixed(1)),
        };
      });
      setFinalResult({ data: transformedData });
    }
  }, [results]);

  useEffect(() => {
    if (stepValuesRef.current) {
      stepValuesRef.current.innerHTML = ""; // Clear previous content

      BoxformattedData.forEach((item, index) => {
        const mainBox = document.createElement("div");
        mainBox.className = "mainBox";
        const labelBox = document.createElement("div");
        labelBox.style.color = "#fff";
        if (index === 0) {
          labelBox.innerText = "Budget";
        } else if (index === BoxformattedData.length - 1) {
          labelBox.innerText = "Actuals";
        }
        else if (index === BoxformattedData.length - 2) {
          labelBox.innerText = "Tail Market";
        }
        else if (index === BoxformattedData.length - 3) {
          labelBox.innerText = "Next5";
        }
        const stepValueBox = document.createElement("div");
        stepValueBox.style.display = "inline-block";
        stepValueBox.style.width = "70px";
        stepValueBox.style.height = "30px";
        stepValueBox.style.margin = "5px";
        stepValueBox.style.color = "#fff";
        stepValueBox.style.textAlign = "center";
        stepValueBox.style.lineHeight = "30px";

        // Set background color based on item type
        if (index === 0 || index === BoxformattedData.length - 1) {
          stepValueBox.style.backgroundColor = "#FFFFFF"; // White for first and last
          stepValueBox.style.color = "#000000"; // Black text for contrast
        } else if (Number(item.BoxValue) <= 0) {
          stepValueBox.style.backgroundColor = "#0B70A9"; // Blue for decreasing step
        } else if (Number(item.BoxValue) > 0) {
          stepValueBox.style.backgroundColor = "#E9D40B"; // Yellow for increasing step
          stepValueBox.style.color = "#000000"; // Black text for contrast
        }

        // Use displayValue for the first and last items, stepValue for others
        stepValueBox.innerText = item.BoxValue;
        mainBox.appendChild(stepValueBox);
        mainBox.appendChild(labelBox);

        if (stepValuesRef.current) {
          stepValuesRef.current.appendChild(mainBox);
        }
      });
    }
  }, [BoxformattedData, formattedData]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="chartTitleData">
          <h2 className="ChartTitle">Q3 Volume Wins and Misses</h2>
          <p>
            ABI closed Q3 volume{" "}
            <span className="yellow">
              {" "}
              {BoxformattedData.length &&
              BoxformattedData[BoxformattedData.length - 1]["BoxValue"] -
                BoxformattedData[0]["BoxValue"] >
                0
                ? "+"
                : ""}
              {BoxformattedData.length > 0
                ? (
                    BoxformattedData[BoxformattedData.length - 1]["BoxValue"] -
                    BoxformattedData[0]["BoxValue"]
                  ).toFixed(1)
                : ""}{" "}
              Mhl
            </span>{" "}
            below Budget with biggest headwind in China. Volumes were overall
            lower than Global (6+6) forecasts by 2.4 Mhl
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="BannData text-center py-5">
                <div className="volumeChart">
                  <div className="subVolumeChart">
                    {formattedData && <WaterfallChart data={formattedData} />}
                    <div className="dis_f_c">
                      <div className="r_90">
                        <p style={{ display: "contents", color: "#fff" }}>Volume (Mhl)</p>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            position: "relative",
                            marginTop: "30px",
                            paddingRight: "20px",
                          }}
                        >
                          {/* box above line */}
                          <hr
                            style={{
                              border: "1px solid #707070",
                              position: "relative",
                              top: "10px",
                              backgroundColor: "black",
                              paddingRight: "20px",
                              // marginBottom:'30px',
                              opacity: "1"
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#000000",
                              color: "#ffffff",
                              padding: "0 10px",
                              borderRadius: "8px",
                              border: "1px solid #707070",
                            }}
                          >
                            {BoxformattedData.length > 0
                              ? (
                                  BoxformattedData[BoxformattedData.length - 1][
                                    "BoxValue"
                                  ] - BoxformattedData[0]["BoxValue"]
                                ).toFixed(1)
                              : ""}{" "}
                            Mhl{" "}
                            {BoxformattedData.length &&
                            BoxformattedData[BoxformattedData.length - 1][
                              "BoxValue"
                            ] -
                              BoxformattedData[0]["BoxValue"] >
                              0
                              ? " higher"
                              : " lower"}{" "}
                            (
                            {BoxformattedData.length
                              ? (
                                  ((BoxformattedData[
                                    BoxformattedData.length - 1
                                  ]["BoxValue"] -
                                    BoxformattedData[0]["BoxValue"]) /
                                    BoxformattedData[0]["BoxValue"]) *
                                  100
                                ).toFixed(1)
                              : ""}
                            %)
                          </span>
                          <div
                            style={{
                              position: "absolute",
                              left: "0%",
                              transform: "translateX(-50%)",
                              color: "#fff",
                              top: "10px",
                            }}

                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="39"
                              viewBox="0 0 5 39"
                              fill="none"
                            >
                              <path
                                d="M2.50586 38.4637L4.48055 35.0435L0.531166 35.0435L2.50586 38.4637ZM2.16383 0.156738L2.16383 35.3855L2.84788 35.3855L2.84789 0.156738L2.16383 0.156738Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              transform: "translateX(50%)",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="39"
                              viewBox="0 0 5 39"
                              fill="none"
                            >
                              <path
                                d="M2.50586 38.4637L4.48055 35.0435L0.531166 35.0435L2.50586 38.4637ZM2.16383 0.156738L2.16383 35.3855L2.84788 35.3855L2.84789 0.156738L2.16383 0.156738Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          ref={stepValuesRef}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            // border: "1px solid #fff",
                            flexWrap: "wrap",
                            paddingTop: "50px",
                            paddingLeft: "0px",
                            paddingRight: "20px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            position: "relative",
                            marginTop: "10px",

                          }}
                        >
                          {/* line below box */}
                          <hr 
                          style={{
                            border: "1px solid #707070",
                            position: "relative",
                            top: "10px",
                            backgroundColor: "black",
                            paddingRight: "20px",
                            marginBottom:'30px',
                            opacity: "1",
                            marginRight: "355px",
                            marginLeft: "130px",
                          }}

                          />

                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "41%",
                              transform: "translateX(-50%)",
                              background: "#000000",
                              color: "#ffffff",
                              padding: "0 10px",
                              borderRadius: "8px",
                              border: "1px solid #707070",
                            }}
                          >
                            Top 10 Market
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinsCharts;

import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IComponentDataItem } from "@amcharts/amcharts5/.internal/core/render/Component";

interface BarChartProps {
  data: { country: string; value: number }[];
  id: string;
  b_color?: string;
  is_xAxis?: boolean;
}

const VBarChart: React.FC<BarChartProps> = ({ data, id, is_xAxis=false , b_color="#000000"}) => {
  useEffect(() => {
    const root = am5.Root.new(id);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Set the background color of the root container to black
    root.container.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color(b_color),
        fillOpacity: 1,
      }),
    });

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        pinchZoomY: false, // Adjusted for vertical pinch zooming
        paddingLeft: 5,
        paddingRight: 0,
      })
    );

    if (root._logo) {
      root._logo.dispose();
    }

    // Set the background color of the chart plot container to black
    chart.plotContainer.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color(b_color),
        fillOpacity: 1,
      }),
    });

    // Add cursor
    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomY", // Adjusted for vertical zooming
      })
    );
    cursor.lineX.set("visible", false); // Adjusted for horizontal bar chart

    // Create axes
    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });
    yRenderer.labels.template.set('visible', is_xAxis)

    yRenderer.labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fill: am5.color(0xffffff),
    });

    yRenderer.grid.template.setAll({
        visible: true,
      });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "country",
        renderer: yRenderer,
        // tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1,
    });

    xRenderer.grid.template.setAll({
      visible: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: xRenderer,
        strictMinMax: true, // Ensures the axis respects the min/max values
        min: (-Math.max(...data.map(d => Math.abs(d.value)))), // Start from the negative max value
        max: (Math.max(...data.map(d => Math.abs(d.value)))), // Ensure max is the largest absolute value
        extraMin: 0.2,
        extraMax: 0.25,
      })
    );
 
    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        sequencedInterpolation: true,
        categoryYField: "country",
        // tooltip: am5.Tooltip.new(root, {
        //   pointerOrientation: "vertical",
        //   labelText: "{valueX}",
        //   visible: false,
        // }),
        clustered: false,
      })
    );

    series.columns.template.setAll({
      cornerRadiusTR: 0,
      cornerRadiusBR: 0,
      fillOpacity: 1,
      strokeOpacity: 0,
      maxHeight: 15,
      // maxWidth:130,
    });

    const positiveGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0x000000), offset: 0 },
        { color: am5.color(0xf5e003), offset: 1 },
      ],
      rotation: 0,
    });

    const negativeGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0x0e7fbe), offset: 0 },
        { color: am5.color(0x000000), offset: 1 },
      ],
      rotation: 0,
    });

    // Color columns based on their value with gradient
    series.columns.template.adapters.add("fillGradient", (fill, target) => {
      const dataItem = target?.dataItem as am5.DataItem<
        IComponentDataItem & { valueX: number }
      >; // Adjust type as per your data structure
      return (dataItem?.get("valueX") ?? 0) < 0
        ? negativeGradient
        : (positiveGradient as any);
    });

    // // Color columns based on their value
    // series.columns.template.adapters.add("fill", (fill, target) => {
    //   const dataItem = target?.dataItem as am5.DataItem<
    //     IComponentDataItem & { valueX: number }
    //   >; // Adjust type as per your data structure
    //   return dataItem?.get("valueX") < 0
    //     ? am5.color(0x0e7fbe)
    //     : am5.color(0xfff1d513);
    // });

    // Add labels to the right of columns
    series.bullets.push((root, series, dataItem) => {
      const value = dataItem.get("valueX");
      return am5.Bullet.new(root, {
        locationX: 1,
        sprite: am5.Label.new(root, {
          text: "{valueX}%",
          fill: am5.color(0xffffff),
          centerX: 8,
          centerY: 18,
          populateText: true,
          dx: value && value < 0 ? -45 : 0,
          // dy: value && value < 0 ? 5 : 5,
        }),
      });
    });

    // series.bullets.push((root, series, dataItem) => {
    //     const value = dataItem.get("valueX");
    //     return am5.Bullet.new(root, {
    //       locationX: 0.5,
    //       locationY: 1,
    //       sprite: am5.Label.new(root, {
    //         fontSize: 18,
    //         text: "{valueX}%",
    //         fill: am5.color(0xffffff),
    //         centerY: value && value < 0 ? am5.p0 : am5.p100,
    //         centerX: am5.p50,
    //         populateText: true,
    //         dy: value && value < 0 ? -5 : 5, // Adjust position for better visibility
    //       }),
    //     });
    //   });
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      stroke: am5.color(0xffffff), // Set color of base line to white
      visible: true, // Ensure base line is visible
      strokeOpacity: 1,
      opacity: 1,
    });

    root.setThemes([myTheme]);

    // Set data
    series.data.setAll(data);
    yAxis.data.setAll(data);

    // Animate appearance
    series.appear(1000);
    chart.appear(1000, 100);

    // Clean up on unmount
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div
      id={id}
      style={{  height: "500px" , borderRadius: "10px"}}
    ></div>
  );
};

export default VBarChart;

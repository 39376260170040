import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IComponentDataItem } from "@amcharts/amcharts5/.internal/core/render/Component";

interface BarChartProps {
  data: { [key: string]: number }; // Updated data type to reflect new input format
  index: number;
}

const HBarChart: React.FC<BarChartProps> = ({ data, index }) => {
  useEffect(() => {
    const root = am5.Root.new("chartdiv_" + index);

    // Apply themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Convert data object to array format
    const chartData = Object.keys(data).map((key) => ({
      category: key,
      value: data[key],
    }));

    // Create chart with background color
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        pinchZoomX: false,
        layout: root.horizontalLayout,
        paddingLeft: 0, // Remove left padding
        paddingRight: 0, // Remove right padding
        paddingTop: 0, // Remove top padding
        paddingBottom: 0, // Remove bottom padding
      })
    );
    chart.leftAxesContainer.setAll({
      width: 0, // Set left axis container width to 0 to remove blank space
    });

    root.container.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color("#1b1b1b"),
        fillOpacity: 0.2,
        height: am5.percent(0),
        width: am5.percent(0),
      }),
    });

    if (root._logo) {
      root._logo.dispose();
    }

    chart.plotContainer.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color("#1b1b1b"),
        fillOpacity: 1,
      }),
    });

    // Add cursor
    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", true);

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
      minGridDistance: 200,
    });

    yRenderer.grid.template.setAll({
      visible: true,
      stroke: am5.color(0xffffff), // Set the grid line color to white
      strokeOpacity: 1, // Ensure the grid line is fully opaque
    });

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });
    xRenderer.labels.template.set("visible", false);

    xRenderer.grid.template.setAll({
      visible: false,
    });

    // Define X-axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
      })
    );
    xAxis.data.setAll(chartData);

    // xAxis.get("renderer").labels.template.setAll({
    //   fill: am5.color(0xffffff),
    //   fontSize: 15,
    //   maxWidth: 100,
    //   oversizedBehavior: "wrap",
    //   textAlign: "center",
    // });

    // Define Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        min: -12,
        max: 20,
        maxDeviation: 1,
        strictMinMax: true, // Ensures the axis respects the min/max values
        // min: -35,
        // max: 30,
      })
    );

    // yAxis.children.unshift(
    //   am5.Label.new(root, {
    //     text: "vs \n Q3 2023",
    //     rotation: 0,
    //     fontWeight: "bold",
    //     fill: am5.color(0xffffff),
    //     y: am5.percent(50),
    //     centerY: am5.percent(50),
    //   })
    // );

    // Define series with tooltip
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Data",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{category}: {value}",
          pointerOrientation: "up",
        }),
      })
    );

    // Customize columns appearance
    series.columns.template.setAll({
      cornerRadiusTL: 0,
      cornerRadiusTR: 0,
      fillOpacity: 1,
      strokeOpacity: 0,
      maxWidth: 20,
    });

    // Define gradient colors
    const positiveGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0xf5e003), offset: 0 },
        { color: am5.color(0x000000), offset: 1 },
      ],
    });

    const negativeGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0x000000), offset: 0 },
        { color: am5.color(0x0e7fbe), offset: 1 },
      ],
    });

    // Color columns based on their value with gradient
    series.columns.template.adapters.add("fillGradient", (fill, target) => {
      const dataItem = target?.dataItem as am5.DataItem<
        IComponentDataItem & { valueY: number }
      >; // Adjust type as per your data structure
      return (dataItem?.get("valueY") ?? 0) < 0
        ? negativeGradient
        : (positiveGradient as any);
    });

    // Display labels on columns
    series.bullets.push((root, series, dataItem) => {
      const value = dataItem.get("valueY");
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          fontSize: 18,
          text: "{valueY}%",
          fill: am5.color(0xffffff),
          centerY: value && value < 0 ? am5.p0 : am5.p100,
          centerX: am5.p50,
          populateText: true,
          dy: value && value < 0 ? -5 : 5,
        }),
      });
    });

    // Add data to series
    series.data.setAll(chartData);

    // Animate on load
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div  id={"chartdiv_" + index} style={{ width: "100%", height: "150px", background:"#1b1b1b" }} />
  );
};

export default HBarChart;

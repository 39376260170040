import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_DEV_CLIENT_ID || "",
    authority: process.env.REACT_APP_DEV_AUTHORITY ,
    redirectUri: process.env.REACT_APP_DEV_REDIRECT_URI ,
    postLogoutRedirectUri: process.env.REACT_APP_DEV_POSTLOGOUT_REDIRECT_URI ,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
    scopes: ["user.read"],
    prompt: 'select_account'
  };
import React, { useEffect, useState } from "react";
import HBarChart from "../../../graphs/HBarChart";

interface Data {
  country: string;
  value: number;
}

interface InputData {
  CPI: Data[];
  DI: Data[];
  IPI: Data[];
  GDP: Data[];
}

interface TransformedData {
  [key: string]: {
    CPI: number;
    DI: number;
    IPI: number;
    GDP: number;
  };
}

const transformData = (data: InputData): TransformedData => {
  const result: TransformedData = {};
  //   if (!data?.CPI?.length || !data?.DI?.length || !data?.IPI?.length || !data?.GDP?.length) {
  //     return result;
  //   }
  // Initialize each country with the keys and set their corresponding values
  data.CPI.forEach((item) => {
    result[item.country] = { CPI: item.value, DI: 0, IPI: 0, GDP: 0 }; // Default values for DI, IPI, GDP
  });

  data.DI.forEach((item) => {
    if (result[item.country]) {
      result[item.country].DI = item.value;
    }
  });

  data.IPI.forEach((item) => {
    if (result[item.country]) {
      result[item.country].IPI = item.value;
    }
  });

  data.GDP.forEach((item) => {
    if (result[item.country]) {
      result[item.country].GDP = item.value;
    }
  });

  return result;
};

interface Props {
  inputData: InputData;
}

const MobileView: React.FC<Props> = ({ inputData }) => {
  const [convertedData, setConvertedData] = useState<TransformedData | null>(
    null
  );

  useEffect(() => {
    const result = transformData(inputData);
    setConvertedData(result);
  }, []);
  console.log("convertedData", convertedData);

  return (
    <div>
      <div className="d-flex text-center">
        <div className="label-text w-100 bold">Demand</div>
        <div className="label-text w-100 bold">Supply</div>
      </div>
      <div className="d-flex text-center pt-4">
        <div className="label-text w-100 f-10">Consumer Price Index</div>
        <div className="label-text w-100 f-10">Disposable Income</div>
        <div className="label-text w-100 f-10">Industrial Production Index</div>
        <div className="label-text w-100 f-10 p-10">GDP</div>
      </div>
      <div className="mobileViewCharts">
        {/* <div className="vertical-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> */}
        {Object.keys(convertedData || {}).map((country, index) => {
          const data = convertedData
            ? convertedData[country]
            : { CPI: 0, DI: 0, IPI: 0, GDP: 0 };
          return (
            <div key={country} className="mt-1">
              <div className="title-text">{country}</div>
              {/* <p>CPI: {data.CPI}</p>
            <p>DI: {data.DI}</p>
            <p>IPI: {data.IPI}</p>
            <p>GDP: {data.GDP}</p> */}
              <HBarChart key={index} data={data} index={index}></HBarChart>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileView;

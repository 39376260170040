import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import VBarChart from "../../../graphs/VBarChart";

interface ExcelData {
  Country: string;
  Period: string | number;
  GDP: number;
}

interface Props {
  updateEventData: (data: any) => void;
}

// Helper function to convert Excel serial date to JavaScript Date object
const excelDateToJSDate = (serial: number) => {
  const date = new Date((serial - 25569) * 86400 * 1000); // Convert Excel serial date to JS date
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
  return formattedDate; // Return date without time
};

const GDP: React.FC<Props> = ({ updateEventData }) => {
  const [percentageData, setPercentageData] = useState<
    { country: string; value: number }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/Q3 Section 3 data.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Read the workbook from the array buffer
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[1]];

        // Convert the worksheet to JSON
        const jsonData = utils.sheet_to_json<any>(worksheet);

        // Filter out rows without valid data and handle Excel date conversion
        const filteredData = jsonData
          .filter(
            (row: any) =>
              row.Country &&
              row.Period &&
              row["Nominal GDP, Calendar Year, lcu_quarterly"] !== undefined
          )
          .map((row: any) => ({
            ...row,
            Period:
              typeof row.Period === "number"
                ? excelDateToJSDate(row.Period) // Convert to string if it's an Excel date
                : row.Period, // Use the string as-is if it's not a number
            GDP: row["Nominal GDP, Calendar Year, lcu_quarterly"],
          }));

        // Group data by Country
        const groupedData: { [key: string]: any[] } = {};
        filteredData.forEach((row: any) => {
          if (row.Country === "Argentina") return;
          if (!groupedData[row.Country]) {
            groupedData[row.Country] = [];
          }
          groupedData[row.Country].push(row);
        });

        // Calculate percentage change for GDP between 2023 and 2024
        const percentageData: { country: string; value: number }[] = [];

        Object.keys(groupedData).forEach((country) => {
          const countryData = groupedData[country];

          // Extract quarter-wise data for 2023 and 2024
          const data2023 = countryData
            .filter((item) => {
              const date = new Date(item.Period);
              return date.getFullYear() === 2023;
            })
            .filter((_item, index) => [0, 3, 6, 9].includes(index)); // Take 1st, 4th, 7th, 10th entries

          const data2024 = countryData
            .filter((item) => {
              const date = new Date(item.Period);
              return date.getFullYear() === 2024;
            })
            .filter((_item, index) => [0, 3, 6, 9].includes(index)); // Take 1st, 4th, 7th, 10th entries

          // Calculate the sum of GDP for 2023 and 2024 quarters
          const sumGDP2023 = data2023.reduce((sum, item) => sum + item.GDP, 0);
          const sumGDP2024 = data2024.reduce((sum, item) => sum + item.GDP, 0);

          // Calculate average by dividing the sum by 12 (as each value represents a quarter)
          const avgGDP2023 = sumGDP2023 / 12;
          const avgGDP2024 = sumGDP2024 / 12;

          // Calculate percentage change
          if (avgGDP2023 !== 0) {
            const percentageChange = (avgGDP2024 / avgGDP2023 - 1) * 100;
            percentageData.push({
              country: country,
              value: isNaN(percentageChange)
                ? 0
                : Number(percentageChange.toFixed(1)),
            });
          } else {
            percentageData.push({
              country: country,
              value: 0,
            });
          }
        });
        updateEventData({ GDP: percentageData });
        setPercentageData(percentageData);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {percentageData.length > 0 && (
        <VBarChart data={percentageData} id="GDP_CHART" />
      )}
    </div>
  );
};

export default GDP;

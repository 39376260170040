import "./App.css";
import "./pages/home/home_style.css";
import "./Responsive.css";
import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./pages/home/Login";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthenticatedTemplate>
          <Routes>
            <Route
              path="/"
              element={
                <Home />
              }
            />
          </Routes>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/" element={<Home />} /> */}
          </Routes>
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IComponentDataItem } from "@amcharts/amcharts5/.internal/core/render/Component";

interface WaterfallChartProps {
  data: {
    columnConfig?: { fill: am5.Color } | undefined;
    Country: string;
    value: number;
    open: number;
    stepValue?: number;
    displayValue: number;
  }[];
}

const WaterfallChart: React.FC<WaterfallChartProps> = ({ data }) => {

  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!chartRef.current) return;

    let root = am5.Root.new(chartRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
      })
    );

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      minorGridEnabled: true,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "Country",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      visible: true,
    });
    // Customize axis labels
    xRenderer.labels.template.setAll({
      fill: am5.color(0xffffff), // White color for text
      background: am5.Graphics.new(root, {
        visible: false,
        fill: am5.color(0x000000), // Black background
      }),
      fontSize: 15, // Font size of the label
      maxWidth: 100, // Maximum width of the label before wrapping
      oversizedBehavior: "wrap",
      textAlign: "center",

      // padding: 8, // Padding around the text
      // cornerRadiusTopLeft: 5, // Rounded corners
      // cornerRadiusTopRight: 5,
      // cornerRadiusBottomLeft: 5,
      // cornerRadiusBottomRight: 5,
    });

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
    });

    yRenderer.grid.template.setAll({
      visible: true,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min:144,
        max: 151,
        renderer: yRenderer,
        // tooltip: am5.Tooltip.new(root, {})
      })
    );
    yAxis.get("renderer").labels.template.setAll({
      forceHidden: true
    });

    if (root._logo) {
      root._logo.dispose();
    }

    yAxis.children.unshift(
      am5.Label.new(root, {
        text: "Volume (Mhl)",
        textAlign: "center",
        y: am5.p50,
        rotation: -90,
        fontWeight: "bold",
        fill: am5.color(0xffffff),
      })
    );

    // Add cursor
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        openValueYField: "open",
        maskBullets: false,
        categoryXField: "Country",
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: "{displayValue}",
        // }),
      })
    );

    series.columns.template.setAll({
      templateField: "columnConfig",
      strokeOpacity: 0,
    });

    series.bullets.push((root, series, dataItem) => {
      const value = dataItem.get("valueY");
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          fontSize: 18,
          text: "{displayValue}",
          fill: am5.color(0xffffff),
          centerY: value && value < 0 ? am5.p0 : am5.p100,
          centerX: am5.p50,
          populateText: true,
          dy: value && value < 0 ? -5 : 5, // Adjust position for better visibility
        }),
      });
    });

    // Define gradients
    const positiveGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0xf5e003), offset: 0 },
        { color: am5.color(0x000000), offset: 1 },
      ],
    });

    const negativeGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0x000000), offset: 0 },
        { color: am5.color(0x0e7fbe), offset: 1 },
      ],
    });

    // Color columns based on their value with gradient
    series.columns.template.adapters.add("fillGradient", (fill, target) => {
      const dataItem = target?.dataItem as am5.DataItem<
        IComponentDataItem & { valueY: number; openValueY: number }
      >; // Adjust type as per your data structure
      return (dataItem?.get("valueY") - dataItem?.get("openValueY") ?? 0) < 0
        ? negativeGradient
        : (positiveGradient as any);
    });

    let stepSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "stepValue",
        categoryXField: "Country",
        noRisers: true,
        stroke: am5.color(0xffffff), // White color for the line
        locationX: 0.65,
        //   stroke: root.interfaceColors.get("alternativeBackground"),
        //   strokeWidth: 2, // Adjust the width of the line as needed
      })
    );

    // Add bullet for displaying value
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5, // Position at the x-axis
        sprite: am5.Label.new(root, {
          text: "",
          centerY: am5.p50, // Adjust to align below the x-axis
          centerX: am5.p50,
          populateText: true,
          fill: am5.color(0x000000),
        }),
      });
    });

    // stepSeries.strokes.template.setAll({
    //   strokeDasharray: [3, 3]
    // });

    let colorSet = am5.ColorSet.new(root, {});

    // Function to add columnConfig based on stepValue
    data.forEach((item, index) => {
      if (index === 0 || index === data.length - 1) {
        item.columnConfig = { fill: am5.color(0x8b8b8b) }; // Cement color for first and last value
      } else if ((item.stepValue ?? 0) < item.open) {
        item.columnConfig = { fill: am5.color(0x0000ff) }; // Blue color for decreasing step
      } else if ((item.stepValue ?? 0) > item.open) {
        item.columnConfig = { fill: am5.color(0xffff00) }; // Yellow color for increasing step
      }
    });

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      stroke: am5.color(0xffffff), // Set color of base line to white
      visible: true, // Ensure base line is visible
      strokeOpacity: 1,
      opacity: 1,
    });

    root.setThemes([myTheme]);

    xAxis.data.setAll(data);
    series.data.setAll(data);
    stepSeries.data.setAll(data);

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);


  return (
    <div
      id="chartdiv"
      ref={chartRef}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default WaterfallChart;

import React from "react";
import "./header.css";
import logo from "../../assets/images/siteLogo.svg";
import User1 from "../../assets/images/user1.png";

export default function header() {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="headerLogo">
              <img src={logo} />
            </div>
          </div>
          <div className="col-6">
            {/* <div className="userSec text-end">
              <img src={User1} /> */}
            </div>
          </div>
        </div>

    </header>
  );
}
